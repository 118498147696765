.header {
  width: 100%;
  background: #001529;
  color: #eaeaea;
  box-sizing: border-box;
  padding-left: 12px;
}
.header .header-left {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  color: #eaeaea;
}
.header .header-left .header-nav-title {
  margin-left: 20px;
}
.header .header-right {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.header .qiut-login {
  padding-right: 50px;
}
.margin-right30 {
  margin-right: 30px;
}
.ant-breadcrumb-link,
.ant-breadcrumb a,
.ant-breadcrumb-separator,
a {
  color: #eaeaea;
}
a:hover,
.ant-breadcrumb a:hover {
  color: #fafafa;
}
.change-company-style {
  margin-right: 20px;
}
.margin-right50 {
  margin-right: 50px;
}
.pointer {
  cursor: pointer;
}
