.user-headimage-pic {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.user-row-view {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.user-row-label-view {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.user-row-value-view {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.card-content-view,
.card-content-view-down {
  border-bottom: none;
  width: 100%;
}
.transfer-style {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px;
  padding-bottom: 15px;
}
.coupon-font1 {
  color: #1DA57A;
}
.addform-view {
  overflow-y: scroll;
}
.hint-div-style {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #d81e06;
  padding: 15px;
  padding-top: 0;
}
.task-add-view {
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
