.form-login-div {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: linear-gradient(to bottom left, #0a5eac, #001529);
}
.form-login-div .form-login {
  min-width: 450px;
  height: atuo;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 60px;
  box-sizing: border-box;
  margin-top: -50px;
  position: relative;
}
.form-login-div .form-login .login-bg-style {
  position: absolute;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  background: #001529;
  -webkit-box-shadow: 5px 5px 20px #001529;
  box-shadow: 5px 5px 20px #001529;
  border-radius: 20px;
}
.form-login-div .form-login .loginform-button-view {
  width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  position: relative;
}
.form-login-div .form-login .loginform-button-view .loginform-button-style {
  width: 100%;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fafafa;
  background: #032d52;
  border: 1px solid #1890ff;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}
.form-login-div .form-login .loginform-bg-view {
  position: absolute;
  opacity: 0.2;
}
.form-login-div .form-login .loginform-input-row-view {
  width: auto;
  height: auto;
  opacity: 1;
  display: flex;
  flex-direction: column;
}
.form-login-div .form-login .loginform-input-row-view .goto-password-hint-view {
  color: #fafafa;
  align-self: flex-end;
  height: 40px;
  line-height: 40px;
}
.form-login-div .form-login .input-style {
  width: 250px;
}
.footer-bar-style {
  position: absolute;
  bottom: 20px;
  font-size: 14px;
  color: #fafafa;
}
.main-footer-pic-view {
  position: absolute;
  width: 733px;
  height: 344px;
  bottom: 0;
  left: 0;
}
.main-top-pic-view {
  position: absolute;
  top: 0;
  right: 0;
  width: 801px;
  height: 431px;
}
