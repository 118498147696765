.nav-left-logo-view {
  width: 150px;
  box-sizing: border-box;
  word-wrap: break-word;
  text-align: center;
  color: #fefefe;
  height: 60px;
  line-height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
.nav-left-content-view,
.nav-left-content-wrap-view {
  position: relative;
  box-sizing: border-box;
}
.nav-left-content-view .header-collapse-view,
.nav-left-content-wrap-view .header-collapse-view {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  box-sizing: border-box;
}
.nav-left-content-view .header-collapse-view .nav-left-toggle-collapsed-view,
.nav-left-content-wrap-view .header-collapse-view .nav-left-toggle-collapsed-view {
  border: 1px solid #fafafa;
}
.nav-left-content-view {
  min-width: 220px;
}
.nav-left-content-wrap-view {
  width: auto;
  align-items: center;
}
