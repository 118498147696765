.mydrawer-add-plan-window-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.mydrawer-add-plan-form-view {
  width: 750px;
  height: auto;
}
.formitem-submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
