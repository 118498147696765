.refund-good-view {
  width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.refund-good-pic-view {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}
.refund-good-right-view {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.align-end {
  align-self: flex-end;
}
.refund-font1 {
  font-size: 12px;
}
