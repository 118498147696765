.container {
  display: flex;
  height: 100%;
}
.container .nav-left {
  width: auto;
  height: calc(100vh);
  background: #001529;
}
.container .main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container .main .top {
  width: 100%;
  height: 100px;
}
.container .main .down,
.container .main .check-hint-view {
  width: 100%;
  padding: 15px;
  flex: 1;
  background: #fff;
  overflow: scroll;
  overflow-x: hidden;
}
.container .main .check-hint-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
.font1 {
  color: #fafafa;
}
.main-headerform-view {
  min-height: 100px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-wrap: wrap;
  background: #eee;
  border: 1px solid #eee;
}
.main-headerform-view .submit-search-style {
  margin-left: 20px;
}
.main-headerform-view .search-input-style2 {
  width: 250px;
}
.main-headerform-view .search-input-style {
  width: 250px;
}
.margintop5 {
  margin-top: 5px;
}
.margintop15 {
  margin-top: 5px;
}
