.window-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.window-view .window-header-view {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.window-view .window-close-view {
  margin-right: 40px;
}
.window-view .window-title-view2 {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-weight: bold;
}
.window-view .ant-card-body {
  flex: 1;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.window-view .ant-card-body .addform-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.window-view .ant-card-body .addform-view .form-top-view {
  width: 100%;
  flex: 1;
  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 30px;
}
.window-view .ant-card-body .addform-view .form-top-view .addform-item-view5 {
  min-width: 420px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.window-view .ant-card-body .addform-view .form-top-view .addform-item-view5 .form-input-style {
  width: 300px;
}
.window-view .ant-card-body .addform-view .form-down-view {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e8e8e8;
  padding: 0;
  margin: 0;
}
.ant-form-item-label {
  margin-right: 15px;
}
