.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}
.main-add-view {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.model-inpu-style {
  width: 100%;
}
.model-hint-font {
  font-size: 12px;
}
.main-headerform-view {
  padding: 30px;
}
