body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.font-primary {
  color: #1da57a;
}
.click-style {
  cursor: pointer;
}
.primary-font-bold {
  color: #1da57a;
  font-weight: bold;
}
.income-font {
  color: #1da57a;
  font-weight: bold;
}
.pay-font {
  color: #d81e06;
  font-weight: bold;
}
.a-font,
.a-font:hover {
  color: #1da57a;
}
.primary-font1 {
  color: #1da57a;
}
.primary-font2 {
  color: #d81e06;
}
.custom-card-view {
  width: 100%;
  padding: 20px 40px;
  padding-bottom: 50px;
  border-bottom: 1px solid #eee;
}
.custom-card-view2 {
  width: 100%;
  border-bottom: 1px solid #eee;
}
.custom-card-header-view {
  width: 100%;
  height: 80px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
}
.custom-card-header-view2 {
  width: 100%;
  height: 60px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
}
.none-bottom {
  border-bottom: none;
}
.margin-top20 {
  margin-top: 20px;
}
.margin-right15 {
  margin-right: 15px;
}
.margin-bottom15 {
  margin-bottom: 15px;
}
.point-cursor {
  cursor: pointer;
}
.hint-font-style {
  color: #d81e06;
  font-weight: bold;
  margin-left: 5px;
  cursor: pointer;
}
.reverse1 {
  transform: scale(-1, 1);
  transform-origin: left  center;
}
.reverse2 {
  transform: scaleX(-1);
}
.window-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.window-view .window-header-view {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.window-view .window-close-view {
  margin-right: 40px;
}
.window-view .window-title-view {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 100px;
  box-sizing: border-box;
  font-weight: bold;
}
.window-view .ant-card-body {
  flex: 1;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.window-view .ant-card-body .addform-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.window-view .ant-card-body .addform-view .form-top-view {
  width: 100%;
  flex: 1;
  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 30px;
}
.window-view .ant-card-body .addform-view .form-top-view .step-view {
  width: 100%;
  padding: 30px;
}
.window-view .ant-card-body .addform-view .form-top-view .step-content-view {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.window-view .ant-card-body .addform-view .form-down-view {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e8e8e8;
  padding: 0;
  margin: 0;
}
.normal-form-view {
  width: 800px;
  padding: 30px;
}
.normal-form-view .layout-center {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.reactquill-mobile-view {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}
.myreact-quil-view {
  width: 375px;
  height: 600px;
}
.myreact-quil-view img {
  width: 100%;
}
.ant-radio-group .ant-radio-button-wrapper {
  margin-right: 20px;
  margin-bottom: 10px;
}
.margin-left20 {
  margin-left: 20px;
}
.margin-buttom10 {
  margin-bottom: 10px;
}
.myformitem-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.margin-top50 {
  margin-top: 50px;
}
