.cs-header-view {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cs-header-view .cs-header-row {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cs-header-view {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: fle;
  align-items: center;
}
.cs-font2 {
  font-size: 32px;
  margin-bottom: 5px;
  font-weight: bold;
}
.task-header-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.select-wrap-view {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.margin-right30 {
  margin-right: 30px;
}
