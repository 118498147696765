.my-edit-window {
  width: 100%;
  overflow: hidden;
}
.my-edit-window .my-edit-view {
  background: #EEE;
  height: 500px;
}
.my-edit-window .my-edit-view p,
.my-edit-window .my-edit-view span {
  padding: 0;
  margin: 0;
}
.myvideo-wrap-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 500px;
}
.mynotes-wrap-column-view .notes-wrap-row-view {
  display: flex;
  flex-direction: row;
}
.custom-upload-view-notes {
  width: auto;
  height: auto;
  min-width: 100px;
  min-height: 100px;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #aaa;
  cursor: pointer;
}
.custom-upload-view-notes .myupload-img-style-notes {
  width: 100px;
  height: 100px;
}
